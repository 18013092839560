<template>
  <div>
    <LayoutDataTable
      :isLoading="isLoading"
      :searchQuery="filter.searchQuery"
      :perPage="pagination.perPage"
      :meta="dataMeta"
      :currentPage="pagination.currentPage"
      :totals="pagination.totals"
      :searchPlaceholder="$t('lbl_search')"
      @setPerPage="(value) => (pagination.perPage = value, pagination.currentPage = 1)"
      @setCurrentPage="(value) => (pagination.currentPage = value)"
      @setSearchQuery="(value) => (filter.searchQuery = value, pagination.currentPage = 1)"
    >
      <template #default>
        <b-row class="flex gap-3 w-100 m-0 mb-2" style="padding: 0 20px">
          <!-- <b-form-datepicker
            :locale="$i18n.locale"
            v-bind="$getCalendarLabel($i18n.locale)"
            id="start-date"
            :placeholder="$t('lbl_select_start_date')"
            v-model="filter.from_date"
            class="mb-2 flex-1"
            today-button
            reset-button
          ></b-form-datepicker>
          <b-form-datepicker
            :locale="$i18n.locale"
            v-bind="$getCalendarLabel($i18n.locale)"
            id="end-date"
            :placeholder="$t('lbl_select_end_date')"
            v-model="filter.to_date"
            class="mb-2 flex-1"
            today-button
            reset-button
          ></b-form-datepicker>-->
          <b-form-select
            class
            style="width: 300px"
            v-model="filter.returnResultStatus"
            :options="STATUS_RETURN_RESULT"
            placeholder="Chọn trạng thái trả KQ"
          />
        </b-row>
        <b-table
          ref="refOrdersTable"
          striped
          hover
          empty-text="Không có dữ liệu"
          :items="ordersData"
          :fields="fieldsTable"
          @row-clicked="handleRowClick"
        >
          <!-- <template #cell(index)="data">{{ data.index + 1 }}</template> -->
          <template #cell(CreatedAt)="data">
            <span class="text-capitalize">
              {{
              appUtils.formatDateTime(data.item.order_date)
              }}
            </span>
          </template>
          <template #cell(result_status)="data">
            <b-badge
              :variant="getResultStatus(data.item.result_status).color"
            >{{ getResultStatus(data.item.result_status).label }}</b-badge>
          </template>
          <!-- <template #cell(index)="data">{{ data.index + 1 }}</template> -->
        </b-table>
      </template>
    </LayoutDataTable>
    <SildebarReturnResultSupplier
      :is-show-slidebar.sync="isShowSlideBar"
      :orderId="selectedOrderID"
      :selectedPage="pagination.currentPage"
      @success="fetchOrdersList"
    />
  </div>
</template>
<script>
import LayoutDataTable from '@/layouts/table/LayoutDataTable.vue'
import { STATUS_RETURN_RESULT, PRODUCT_TYPES } from '@/utils/constant'
import SildebarReturnResultSupplier from './components/SildebarReturnResultSupplier'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import appUtils from '@/utils/appUtils'
import i18n from '@/libs/i18n'
import Vue from 'vue'

export default {
  name: 'Result',
  components: {
    LayoutDataTable,
    // vSelect,
    SildebarReturnResultSupplier
  },
  data() {
    return {
      appUtils,
      isLoading: false,
      filter: {
        searchQuery: '',
        from_date: '',
        to_date: '',
        returnResultStatus: ''
      },
      pagination: {
        perPage: 10,
        currentPage: 1,
        totals: 0,
        total_page: 0
      },
      STATUS_RETURN_RESULT,
      PRODUCT_TYPES,
      isShowSlideBar: false,
      ordersData: [],
      data: [],
      processor_orders: [],
      fieldsTable: [
        {
          key: 'order_code',
          label: 'Mã đơn hàng'
        },
        {
          key: 'contact_name',
          label: 'Người thụ hưởng'
        },
        {
          key: 'CreatedAt',
          label: 'Ngày đặt'
        },
        {
          key: 'result_status',
          label: 'Trạng thái trả KQ'
        }
      ],
      selectedRow: {},
      selectedOrderID: 0
    }
  },
  computed: {
    dataMeta() {
      const localItemsCount = this.$refs.refOrdersTable
        ? this.$refs?.refOrdersTable?.localItems?.length
        : 0
      return {
        from:
          this.pagination.perPage * (this.pagination.currentPage - 1) +
          (localItemsCount ? 1 : 0),
        to:
          this.pagination.perPage * (this.pagination.currentPage - 1) +
          localItemsCount,
        of: this.pagination.totals
      }
    }
  },
  mounted() {
    this.fetchOrdersList()
  },
  watch: {
    'pagination.perPage'() {
      if (this.pagination.currentPage !== 1) this.pagination.currentPage = 1
      this.fetchOrdersList()
    },
    'pagination.currentPage'() {
      this.fetchOrdersList()
    },
    'filter.searchQuery'() {
      if (this.pagination.currentPage !== 1) this.pagination.currentPage = 1
      this.fetchOrdersList()
    },
    'filter.returnResultStatus'() {
      if (this.pagination.currentPage !== 1) this.pagination.currentPage = 1
      this.fetchOrdersList()
    },
    selectedRow(value) {
      this.processor_orders = this.data.find(
        elm => elm.supplier_order.ID === value.ID
      )?.order_item
    }
  },
  methods: {
    getResultStatus(status) {
      switch (status) {
        case 1:
          return {
            label: 'Chưa trả kết quả',
            color: 'secondary'
          }
        case 2:
          return {
            label: 'Cần bổ sung kết quả',
            color: 'warning'
          }
        case 3:
          return {
            label: 'Đã trả kết quả',
            color: 'success'
          }
      }
    },
    handleRowClick(item) {
      this.$router.push({
        name: 'DetailReturnResult',
        params: { id: item.id },
        query: {
          page: this.pagination.currentPage,
          total_page: this.pagination.total_page
        }
      })
      if (this.filter.searchQuery) {
        this.$router.push({
          query: {
            keyword: this.filter.searchQuery
          }
        })
      }
      // this.isShowSlideBar = true
      this.selectedOrderID = item.id
    },
    async fetchOrdersList(ctx, callback) {
      const userData = appUtils.getLocalUser()
      this.isLoading = true
      await Vue.prototype.$rf
        .getRequest('CommonRequest')
        .fetchListOrdersSupplier({
          keyword: this.filter.searchQuery,
          page_size: this.pagination.perPage,
          page_num: this.pagination.currentPage,
          supplier_id: userData?.supplierInfo?.id,
          sort_by: 'id',
          order: 'desc',
          type: PRODUCT_TYPES.SERVICE,
          status: this.filter.returnResultStatus
        })
        .then(response => {
          const orders = response?.data?.data?.map(elm => {
            return {
              ...elm,
              contact_name: elm.order_contact[0]?.name
            }
          })
          this.pagination.totals = response.data?.page?.total
          this.pagination.total_page = response.data?.page?.total_page
          this.ordersData = orders || []
          this.data = response.data?.data
          this.$store.commit('orders/SET_LIST_ORDER_IN_PAGE', this.ordersData)
          callback && callback(orders)

          // return orders
        })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t('lbl_error_fetching_data'),
              icon: 'AlertTriangleIcon',
              variant: 'danger'
            }
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep.v-select {
  .vs__dropdown-toggle {
    height: 100%;
  }
}
.cs-tr-table {
  height: 40px !important;
}

.cs-header-col * {
  word-break: break-word !important;
}

.cs-bg-row {
  background-color: #e2f7fe !important;
}

::v-deep.el-table {
  .el-table__row {
    height: 40px;
  }
}
</style>

